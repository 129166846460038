html, body, #react-app {
    height: 100%;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    background-color: #E5E5E5;
    font-size: 15px;
    font-weight: 600;
    color: #3B4370;
}

.containerMenu .glyphicon {
    margin-right: 10px;
}

a:hover {    
    text-decoration: none;
}
a:focus {    
    text-decoration: none;
}
a:visited {    
    text-decoration: none;
}

.active {    
    font-size: 18px;
    align-items: baseline;
}

/* Highlighting rules for nav menu items */
.main-nav li a.active,
.main-nav li a.active:hover,
.main-nav li a.active:focus {
    background-color: #4189C7;
    color: white;
    display: flex;
}

.desactive {    
    font-size: 18px;
    align-items: baseline;
    cursor:no-drop;
} 

/* Keep the nav menu independent of scrolling and on top of other items */
.main-nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.ReactTable {
    border: none;
}

.ReactTable * {
    font-size: 15px;
}

.tableHeight {
    height: 60vh;
}
.tableHeightSmall {
    height: 20vh;
}
.tableHeightMedium {
    height: 60vh;
}
.tableHeightReductionActual {
    height: 67vh;
}
.heightHisto {
    height: 65vh;
    z-index: 0;
}
.heightHisto .rt-tr {
    line-height: 31px;
}
.reappro-table .rt-td {
    height: 100%;
}
.tableHeightContact {
    height: 23vh;
}
.shelvingTrayTable {
    height: 30vh;
}
.settingsMaxQty{
    margin-top: 2%;
}
.settingsAutoLogout{
    margin-top: 1%;
}
.settingsExpiration{
    margin-top: 2%;
}
.settingspProductCodeUsage{
    margin-top: 1%;
}
.settingsReturnHome {
    margin-top: 1%;
}
.settingsMinQty {
    margin-top: 5%;
}
.settingsMinVolume {
    margin-top: 4%;
}
.settingsTitleTickets {
    font-size: 22px;
}
.settings {
    margin-bottom: 1%;
}
.settingsCard {
    margin: 5px 0px 0px;
}
.settingsTimeSalesMin {
    margin-bottom: 4%;
}
.settingsCurrency {
    height: 32vh;
}
.settingsHoppers {
    height: 77vh;
}

.shelvingTrayFormCol {
    margin-bottom: 15px;
}

.pricePeriodFormWidth {
    width: 17%;
}
@media (max-width: 1800px) {
    .settings textarea {
        height: 70px;
    }
}

@media (min-width: 1800px) {
    .tableHeight {
        height: 51vh;
    }
    .tableHeightSmall {
        height: 40vh;
    }
    .tableHeightMedium {
        height: 70vh;
    }
    .tableHeightReductionActual {
        height: 73vh;
    }
    .heightHisto {
        height: 70vh;
    }
    .shelvingTrayTable {
        height: 36vh;
    }
    .settingsMaxQty, .settingsReturnHome, .settingsAutoLogout, .settingspProductCodeUsage {
        margin-top: 16px;
    }
    .settingsTitleTickets {
        margin-bottom: 7px;
    }
    .settings {
        margin-bottom: 2%;
    }
    .settingsCard {
        margin: 1% 0px 0px;
    }
    .settingsTimeSalesMin {
        margin-bottom: 6%;
    }
    .settingsHoppers {
        height: 70vh;
    }
    .shelvingTrayFormCol {
        margin-bottom: 2%;
    }
    .pricePeriodFormWidth {
        width: 13%;
    }
    .settingsMinVolume, .settingsMinQty {
        margin-top: 4%;
    }
}

@media (max-width: 767px) {
    /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
    body {
        padding-top: 50px;
    }
}

@media (min-width: 768px) {
    /* On small screens, convert the nav menu to a vertical sidebar */
    .main-nav {
        height: 100%;
        width: calc(25% - 20px);
    }
    .main-nav .navbar {
        border-radius: 0px;
        border-width: 0px;
        height: 100%;
    }
    .main-nav .navbar-header {
        float: none;
    }
    .main-nav .navbar-collapse {
        border-top: 1px solid #444;
        padding: 0px;
    }
    .main-nav .navbar ul {
        float: none;
    }
    .main-nav .navbar li {
        float: none;
        font-size: 15px;
        margin: 6px;
    }
    .main-nav .navbar li a {
        padding: 10px 16px;
        border-radius: 4px;
    }
    .main-nav .navbar a {
        /* If a menu item's text is too long, truncate it */
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.rc-select {
    width: 100%;
    height: 40px;
}

.rc-select-dropdown{
    z-index: 9999 !important;
}

li.rc-select-dropdown-menu-item {
    border: 1px solid rgba(59, 67, 112, 0.08);
    font-weight: bold;
    color: rgb(0, 0, 0);
}

li.rc-select-dropdown-menu-item-active {
    background-color: rgba(59, 67, 112, 0.1);
    color: rgb(0, 0, 0);
}

.selectors .rc-select-selection--single {
    background-color: transparent;
    height: 40px;
    border: 4px;
}
.selectors .rc-select-selection--single .rc-select-selection__rendered {
    background-color: transparent;
    height: 40px;
}
.selectors .rc-select-selection-selected-value, .borderSelectors .rc-select-selection-selected-value {
    top: 10%;
}
.selectors .rc-select-arrow, .borderSelectors .rc-select-arrow {
    top: 15%;
}
.selectors .rc-select-arrow b, .borderSelectors .rc-select-arrow b {
    border-color: #3B4370 transparent transparent transparent;
}
.selectors .rc-select-open .rc-select-arrow b, .borderSelectors .rc-select-open .rc-select-arrow b {
    border-color: transparent transparent #3B4370 transparent;
}
.borderSelectors .rc-select-selection--single {
    border: 1px solid #ccc;
    background-color: transparent;
    height: 40px;
}

.rt-thead.-filters {
    background-color: #3B4370;
}

.ReactTable .rt-thead .rt-th {
    border-right: 1px solid rgba(0,0,0,0.1);
}

.react-datepicker {
    display: flex;
}

.react-datepicker-wrapper {
    width: 100%;
}
.react-datepicker__input-container {
    width: 100%;
}

div::-webkit-scrollbar:vertical {
    width: 8px;
}
div::-webkit-scrollbar:horizontal {
    height: 8px;
}
div::-webkit-scrollbar {
    -webkit-appearance: none;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #646880;
}


.tabs {
    width: 400px;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;
}

.tab-links {
    margin: 0;
    padding: 0;
    border: 1px solid #CCC;
    border-bottom: 1px solid #868686;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.tab-link {
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 0;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    background-color: rgba(59,67,112, 0.05);
}

.tab-link-active {
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 0;
    border-radius: 4px;
    background-color: rgba(59,67,112, 0.25);
}

.btn-primary {
    color: #fff;
    background-color: #007AFF;
    border-color: #007AFF;
    font-size: 16px;
    font-weight: 900;
}

.btn-primary:hover {
    background-color: #007AFF;
    border-color: #007AFF;
}

.btn-secondary {
    background-color: rgba(191, 191, 191, 1);
    box-shadow: 0px 0px 20px rgba(66, 73, 120,.1);
    color: #3B4370;
    font-weight: 900;
}

.stock-table .borderSelectors {
    width: 430px;
}

.rt-th {
    align-self: center;
    font-size: 13px;
}

.rt-td {
    place-self: center;
    text-align: -webkit-center;
}

.rt-tbody { 
    border: 1px solid rgba(66, 73, 120, 0.1);
    line-height: normal;
    overflow-x: hidden !important;
    overflow-y: overlay !important;
}

.rt-tbody::-webkit-scrollbar:vertical {
    width: 8px;
}
.rt-tbody::-webkit-scrollbar:horizontal {
    height: 8px;
}
.rt-tbody::-webkit-scrollbar {
    -webkit-appearance: none;
}
.rt-tbody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #646880;
}

.scroll-modal::-webkit-scrollbar:vertical {
    width: 8px;
}
.scroll-modal::-webkit-scrollbar {
    -webkit-appearance: none;
}
.scroll-modal::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #646880;
}

.hover-blue:hover {
    color: #337ab7;
}

.rt-thead.-headerGroups .rt-th {
    border: 1px solid rgba(59, 67, 112, 0.16);
}

.planogram button {
    line-height: 0px !important;
}

input[type=file] {
    border: none;
}
input[type=file]::-webkit-file-upload-button {
    font-family: 'Poppins', sans-serif !important;
    font-weight: 900;
    font-size: 15px;
    background-color: #007AFF;
    color: white;
    border: none;
    height: 32px;
    width: 47%;
    border-radius: 3px;
}

@keyframes blink-error {
    0% { border-color: red;}
    50% {border-color: transparent;}
    100% {border-color: red;}
}

@-webkit-keyframes blink-error {
    0% { border-color: red;}
    50% {border-color: transparent;}
    100% {border-color: red;}
}

.blink-error {
    animation: blink-error 1s step-start 0s infinite;
    -webkit-animation: blink-error 1s step-start 0s infinite;
    border: 2px solid red;
}

.menuButton {
    background: transparent;
    border: none;
    position: fixed;
    left: 250px;
    z-index: 998;
    top: 10px;
    outline: none;
}

.menu {
    position: relative;
    left: 0;
    min-width: 270px;
    max-width: 270px;
    height: 100vh;
    background: #040930;
    font-size: 18px;
    overflow: hidden;
}

.menu-minimal {
    position: relative;
    left: 0;
    min-width: 70px;
    max-width: 70px;
    max-height: 100%;
    background: #040930;
    font-size: 22px;
    overflow: hidden;
}

.app-container {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.hiddenMenu {
    display: none;
    pointer-events: none;
}

.fixedApp {
    left: -200px;
}

.fixedButton {
    left: 50px;
}

.acc-kontainer {
    width: 100%;
}

.iconSubmenu path, .iconSubmenu circle {
    fill: transparent;
}

.acc-kontainer .acc-body {
    width: 98%;
    width: calc(100% - 20px);
    margin: 0;
    height: 0;
    color: rgba(0, 0, 0, 0);
    line-height: 28px;
    box-sizing: border-box;
    transition: 0.5s;
}

.acc-kontainer label {
    cursor: pointer;
    display: block;
    padding: 15px;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    z-index: 100;
}
  
.acc-kontainer input{
    display: none;
}
  
.acc-kontainer input:checked+label {
    background-color: rgba(59, 67, 112, 0.5);
}

.singleItem {
    background-color: initial !important;
}

.acc-kontainer a:focus .active {    
    background: #007AFF;
    padding: 5px;
    border-radius: 10px;
    display: flex;
}

.acc-kontainer a:hover .active {    
    background: #007AFF;
    padding: 5px;
    border-radius: 10px;
    display: flex;
}

.acc-kontainer a:active .active {    
    background: #007AFF;
    padding: 5px;
    border-radius: 10px;
    display: flex;
}

.acc-kontainer input:checked~.acc-body {
    height: auto;
    color: #fff;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    transition: 0.5s;
    display: block;
    background-color: rgba(59, 67, 112, 0.5);
}
.acc-kontainer input:checked~.acc-body .iconSubmenu path, .acc-kontainer input:checked~.acc-body .iconSubmenu circle {
    fill: white;
}

label {
    margin-bottom: 0px;
}

.acc-kontainer .language label:before {
    font-family: 'FontAwesome';
    content: '\f078';
    font-weight: bolder;
    float: right;
}

.ml-50 {
    margin-left: 50px;
}

.ml-25 {
    margin-left: 25px;
}

.containerMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bottom {
    position: absolute;
    bottom: 0;
    margin-bottom: 2rem;
}

.logo {
    flex: 0 0 auto;
    min-width: 100px;
    margin: 30px 0 30px 5px;
    position: sticky;
    z-index: 998;
    background-color: #040930;
    padding-bottom: 20px;
}

.logo-minimal {
    flex: 0 0 auto;
    margin: 50px 0 30px 5px;
    position: sticky;
    z-index: 998;
    background-color: #040930;
    padding-bottom: 20px;
}

.selectors .rc-select-selection--single {
    height: 40px;
    background-color: transparent;
    min-width: 110px;
}

.language-selector {
    margin-top: 15px;
}

.language-selector .rc-select-selection--single {
    background-color: transparent;
    outline: none;
    border: none;
    color:  #fff
}

.language-selector .rc-select-selection-selected-value {
    color: white !important;
}

.rc-select-selection--single .rc-select-selection__rendered {
    background-color: transparent;
}

.language-selector .rc-select-selection--single .rc-select-selection__rendered {
    margin-left: 0;
    background-color: transparent;
}

.buttonModal {
    background: #fff;
    color: #3B4370;
    font-size: 15px;
}

.react-datepicker__input-container input {
    height: 40px;    
    background: transparent;
    border: 1px solid rgba(59, 67, 112, 0.15);
    border-radius: 4px;
}

.chart-ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    list-style-type: none;
    text-align-last: justify;
    margin-left: 10%;
}

/*stock page help*/
.margin {
    margin-bottom: 2%;
}

.textCenter {
    height: 40px;
    line-height: 40px;
    text-align: initial;
}

.input-planogram .rc-select-selection.rc-select-selection--single {
    border: none;
    flex: 1;
    box-shadow: 0px 0px 20px rgba(66, 73, 120, .1);
    background-color: transparent;
    height: 32px;
}

.rc-select-enabled .rc-select-selection:hover, .rc-select-focused .rc-select-selection {
    box-shadow: none;
}

.ml-5 {
    margin-left: 5px;
}

input {
    background: transparent;
    border: 1px solid rgba(59, 67, 112, 0.15);
}

textarea {    
    border: 1px solid rgba(59, 67, 112, 0.15);
    border-radius: 4px;
}

.cash-font-dm div {
    color: #181F3A !important;
}

.histo-date {
    color: rgba(59, 67, 112, 0.7);
    padding: 5% 0;
    cursor: pointer;
    text-align: left;
    border-bottom: 1px solid rgba(59, 67, 112, 0.16);
    font-weight: bold;
}

/* =================================== DARK MODE =================================== */
#switch {
  display: none;
}
/* Main changes */
#switch:checked + .app {
  background: #0E1630;
}

#switch:checked + .app .white-font-dm {
    color: white !important;
}

#switch:checked + .app .app-container div {
    color: white
}

.card {
    box-shadow: rgb(0, 0, 0, 0.2) 0px 4px 8px 0px;
}

/* Card changes */
#switch:checked + .app .card{
    background-color: #181F3A !important;
    color: white;
}


#switch:checked + .app .acc-kontainer a:focus .active {    
    background: #F2C94C;
    color: #181F3A !important;
    display: flex;
}

#switch:checked + .app .acc-kontainer a:hover .active {    
    background: #F2C94C;
    color: #181F3A !important;
    display: flex;
}

#switch:checked + .app .acc-kontainer a:active .active {    
    background: #F2C94C;
    color: #181F3A !important;
    display: flex;
}

#switch:checked + .app .active.acc-body:hover .active {
    color: #181F3A !important;
    display: flex;
}

/* Input changes */
#switch:checked + .app input {
    background-color: #181F3A !important;
    color: white;
    border: 1px solid white;
}
#switch:checked + .app input[type=file] {
    border: none;
}
#switch:checked + .app input[type=file]::-webkit-file-upload-button {
    background: #F2C94C;
    color: #181F3A !important;
}

/* Button changes */
#switch:checked + .app .btn-primary {
    color: #181F3A !important;
    background-color: #F2C94C;
    border-color: #F2C94C;
}

#switch:checked + .app .btn-primary {
    color: #181F3A !important;
    background-color: #F2C94C;
    border-color: #F2C94C;
}

#switch:checked + .app .btn-primary:hover {
    color: #181F3A !important;
    background-color: #F2C94C;
    border-color: #F2C94C;
}

/* Datepicker changes */
#switch:checked + .app .react-datepicker * {
    color: initial !important;
}

/* Selector changes */
#switch:checked + .app .app-container .rc-select-selection--single {
    background: #181F3A !important;
    border: 1px solid;
}

/* Products font changes */
#switch:checked + .app .products * {
    color: white !important;
}

/* Icon changes */
#switch:checked + .app .app-container .icon-dm {
    color: #000 !important;
}

#switch:checked + .app .app-container .icon-container-dm {
    background-color: #F2C94C !important;
}

/* Checkbox changes */
#switch:checked + .app .checkbox-dm {
    background-color: #FFFFFF !important;
    border: 3px solid #FFFFFF !important;
}

#switch:checked + .app .icon-check-dm {
    color: #0E1630 !important;
}

#switch:checked + .app .offer-checkbox {
    background-color: #FFFFFF !important;
    border: 3px solid #FFFFFF !important;
    color: #0E1630 !important;
}

/* Text danger and warning changes */
#switch:checked + .app .text-danger {
    background-color: #181F3A !important;
    color: red;
}

#switch:checked + .app .text-warning {
    background-color: #181F3A !important;
    color: red;
}

#switch:checked + .app textarea {
    background: #181F3A;
    color: white;
    border: 1px solid white;
}

/* table changes */
#switch:checked + .app .rt-thead.-headerGroups .rt-th {
    border: 1px solid rgba(0, 0, 0, 0.1);
}
#switch:checked + .app .rt-thead.-filters {
    background-color: rgba(255, 255, 255, 0.35);
}
/* tab changes */
#switch:checked + .app .tab-link {
    background-color: rgba(59,67,112, 0.4);
}

#switch:checked + .app .tab-link-active {
    background-color: rgba(59,67,112, 1);
}

#switch:checked + .app .robot-control  button {
    color: white;
}

/* icons changes */
#switch:checked + .app .menu .acc-body:hover .active svg path{
    fill: black;
}

/*histo-date changes */
#switch:checked + .app .histo-date {
    color: rgba(59, 67, 112, 0.7);
}
/* ================================= END DARK MODE ================================= */

/* Table fix */
.offer-table  .rt-thead.-header {
    height: 60px;
}

.offer-table  .rt-tr {
    height: 40px;
}

.offer-table .ReactTable input {
    height: 30px !important;
}

.ReactTable input {
    height: 25px !important;
}

.rt-td {
    padding: 2px !important;
}

.offer-table .ReactTable .rc-select-selection.rc-select-selection--single {
    height: 30px;
}
.offer-table .rc-select-selection-selected-value, .offer-table .rc-select-arrow {
    top: 0;
}

div.rt-tr {
    align-items: center;
}

div.rt-td { align-self: center; }

.rc-select-dropdown.rc-select-dropdown--single.rc-select-dropdown-placement-topLeft{
    width: 210px !important;
}

.form-control-static {
    min-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.reappro-table .rt-tfoot .rt-tr .rt-td div {
    padding-top: 10px !important;
}

.heightHisto .rt-tfoot .rt-tr .rt-td {
    height: 100%;
}

.ReactTable .rt-tfoot .rt-td {
    border-bottom: 1px solid rgba(0,0,0,.05);
}

.truncate-long-texts {
    width: 210px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.robotAnmation {
    max-width: 600px;
    max-height: 200px;
    min-width: 600px;
    min-height: 200px;
}

.item-offer-select {
    width: 100% !important;
}

.item-offer-select .rc-select-selection.rc-select-selection--single {
    min-width: 0px !important;
}

.rt-thead .rt-tr .rt-th {
    height: 100%;
}

.home-events-table .rt-tbody{
    max-height: 35vh;
}

.products {
    height: 100%;
}

.offer-table .ReactTable .rt-table .rt-tbody{
    max-height: 70vh;
}

.planogram {
    height: 100%;
}

.reorder-table .rt-tbody{
    max-height: 70vh;
}

.ReactTable .rt-tbody .rt-td {
    height: 100%;
}

.stock-table .rt-tbody{
    max-height: 85vh;
}

.alarms-events-table .rt-tbody{
    height: 20vh;
}

.test-event-table  .rt-tbody{
    height: 20vh;
}

/* Remove arrows for number inputs Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* Remove arrows for number inputs Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.iframe-loader {
    background:url(../assets/images/loader.gif) center center no-repeat;
}

.preloader {
    width: 100px;
    height: 100px;
    border: 10px solid #D4EFD2;
    border-top: 10px solid #027737;
    border-radius: 50%;
    animation-name: turn;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    position: absolute; 
    top: 50%; 
    left: 50%; 
    z-index: 999;
}
@keyframes turn {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
/**react-windowed-select classes**/
.css-1okebmr-indicatorSeparator{
    width: 0px !important;
}

.css-19bqh2r{
    display: none !important;
}

.css-1wy0on6{
    position: relative !important ;
}

.css-tlfecz-indicatorContainer {
    border-color: #999999 transparent transparent transparent;
    border-style: solid;
    border-width: 5px 4px 0 4px;
    height: 0;
    width: 0;
    margin-left: -15px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0px !important;
}

.helpPdf iframe{
  position: relative;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}

.salesTariffSelect .rc-select-selection.rc-select-selection--single {
    box-shadow: 0px 0px 20px rgba(66, 73, 120, .1);
    height: 30px;
    margin-left: 10px;
}

.salesTariffInput  {
    width: 100px !important;
    display: inline-block !important;; 
}

.lockerPlanoItem1 { grid-area: plano; }
.lockerPlanoItem2 { grid-area: lockerInfo; }
.lockerPlanoItem3 { grid-area: prodInfo; }

.gridContainerLockerPlano {
    display: grid;
    grid-template-areas:
        'plano plano plano plano plano'
        'lockerInfo lockerInfo prodInfo prodInfo prodInfo';
    gap: 10px;
    padding: 15px;
}

